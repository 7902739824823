<div class="ma-login" fxLayout="row">
  <div
    class="ma-login__form"
    [ngClass]="'desktop'"
    fxFlex="38"
    fxLayoutAlign="start start"
    fxLayout="column"
  >
    <div class="ma-login__logo" fxLayout="row">
      <img src="../../../assets/logo_slogan.svg" />
      <div fxFlex></div>
    </div>

    <div class="ma-login__text" [ngClass]="'desktop'">
      <h2>
        {{ "PaymentPaymentRequestTitle" | translate }}
      </h2>
      <h3>
        {{ "PaymentDownloadToContinue" | translate }}
      </h3>
      <h3>
        {{ "PaymentNoAppYet" | translate }}
      </h3>

      <div class="links">
        <div class="badge">
          <img src="../../assets/apple-placeholder.png" />
          <img src="../../assets/google-play-placeholder.png" />
        </div>
      </div>

      <h3>
        {{ "PaymentRequestRemoval" | translate }}
      </h3>
      <a href="mailto:kontakt@fedinvest.al?subject=Account removal">
        {{ "PaymentRemovalLink" | translate }}</a
      >
    </div>
  </div>
  <div class="ma-login__banner" fxFlex fxLayoutAlign="center center"></div>
</div>
